<template>
  <v-app class="lato-font">
    <div class="row marg-0 ">
      <div class="col-md-8 col-sm-12 col-12  padd-r-40 padd-t-128 marg-b-94 padd-l-0 padd-r-0-mob">
        <div class="row marg-0 align-center">
          <span class="fsize24 clr-000000">Aadhaar KYC (Digilocker)</span>
        </div>
        <div class="margin-t-38 fsize14 clr-000000">
          <span>
            <p>
              <label class="marg-0">
            Share a PDF copy (name, gender, DoB, address and photo) of your
            Aadhaar from your Govt.</label>
             <label class="marg-0">
             DigiLocker account for KYC. The PDF WILL NOT
            contain your Aadhaar number. </label><p>
            <div class="marg-t-18">
              <p class="fsize14">
                <label class="marg-0">
                The Aadhaar and the PAN DDTPM2796G should belong to you, the
                applicant. If it does not, </label>

                <span class="clr-0073bc">start over.</span>
              </p>
            </div>
          </span>
        </div>

        <div class="margin-t-38">
          <router-link
            to="/profile"
            active-class="active"
            tag="button"
            exact
            class="btn-color fsize18 padd-0-16 bor-radius h-40 w-100per"
          >
            Continue to DigiLocker</router-link
          >
        </div>
        <div class="marg-t-16">
          <p class="clr9b9b fsize12 marg-0">
            <label class="marg-0">
            *By Clicking the above button, I agree that I have read the
            <span class="clr-0073bc">Aadhaar user consent document </span>
            aoluntarily </label><label class="marg-0">
            consent to share my Aadhaar information (excluding the aadhaar
            number) with Amoga for conducting KYC for opening a trading and
            Demat account adhering to KRA regulation (2011) and PMLA guideline
            (2002).</label>
          </p>
        </div>
      </div>
      <div class="col-md-4 col-sm-12  padd-0 padd-0-20 backgroundclr-efef">
        <div class="marg-t-40">
          <span class="fsize14 fw-bold clr-000000"
            >Why do we need your Aadhaar?</span
          >
        </div>
        <div class="marg-t-18">
          <span class="fsize12 clr-000000">
            <p><label class="marg-0">
            The Aadhaar PDF copy (which does not contain </label>
            <label class="marg-0">
            your Aadhaar number) from DigiLocker is used as an </label>
            identity and address proof for KYC.<br /></p>
            <div class="marg-t-18">
              Your Aadhaar number is never visible or available <br />
              to us.
            </div>
          </span>
        </div>
        <div class="marg-t-16">
          <span class="fsize14 fw-bold clr-000000">What is DigiLocker?</span>
          <div class="marg-t-14">
            <p class="fsize12">
              <label class="marg-0">
              DigiLocker is a Government of India portal from</label>
              <label class="marg-0">
               where you can
              obtain original digital copies of your </label><label class="marg-0">
               government issued
              documents. <span class="clr-0073bc">Know more</span>.</label>
            </p>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
</style>